








import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import UserDetailTemplate from '@/components/users/UserDetailTemplate.vue';
import { UpdateUserForAxios } from '@/models/users';
import { UserStores } from '@/store/users';
@Component({
  components: { Page, BackButtonLayout, UserDetailTemplate },
})
export default class ViewUsers extends Vue {
  userDetailStore = UserStores.detail;
  userStateStore = UserStores.state;

  currentFormData: UpdateUserForAxios = {
    id: Number(this.$route.params.id),
    firstname: ``,
    lastname: ``,
    email: ``,
    password: ``,
    force_update_password: false,
    mobile: ``,
    birthday: ``,
    role: ``,
    address: ``,
    address2: ``,
    city: ``,
    state: ``,
    postcode: ``,
    degree: ``,
    university: ``,
    gender: ``,
    profile_url: ``,
  };

  get layoutTitle() {
    return this.currentFormData.firstname !== ''
      ? `${this.currentFormData.firstname}'s Profile`
      : '';
  }

  get backRoute() {
    return `/users`;
  }

  mounted() {
    this.userStateStore.setIsModified(false);
    this.userDetailStore.retrieveUserById(Number(this.$route.params.id));
  }

  @Watch(`userDetailStore.fetchedUserResponse`)
  populateCurrentForm() {
    if (this.userDetailStore.fetchedUserResponse) {
      this.currentFormData = {
        ...this.userDetailStore.fetchedUserResponse,
      };
    }
  }
}
